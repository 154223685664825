// @ts-nocheck
import Airtable from 'airtable';

const bases = {
  production: new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_PROD_API_KEY
  }).base(process.env.REACT_APP_AIRTABLE_PROD_BASE_ID),
  development: new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_DEV_API_KEY
  }).base(process.env.REACT_APP_AIRTABLE_DEV_BASE_ID)
};

const tables = {
  cards: {
    production: bases.production(process.env.REACT_APP_AIRTABLE_TABLE_NAME_1),
    development: bases.development(process.env.REACT_APP_AIRTABLE_TABLE_NAME_1)
  },
  attempts: {
    production: bases.production(process.env.REACT_APP_AIRTABLE_TABLE_NAME_2),
    development: bases.development(process.env.REACT_APP_AIRTABLE_TABLE_NAME_2)
  },
  images: {
    production: bases.production(process.env.REACT_APP_AIRTABLE_TABLE_NAME_3),
    development: bases.development(process.env.REACT_APP_AIRTABLE_TABLE_NAME_3)
  },
  dailyEmailNotifications: {
    production: bases.production(process.env.REACT_APP_AIRTABLE_TABLE_NAME_4),
    development: bases.development(process.env.REACT_APP_AIRTABLE_TABLE_NAME_4)
  },
  actionsCountByDate: {
    production: bases.production(process.env.REACT_APP_AIRTABLE_TABLE_NAME_5),
    development: bases.development(process.env.REACT_APP_AIRTABLE_TABLE_NAME_5)
  }
};

export default tables;
