import getTable from '../helpers/getTable';
import shuffleArray from '../utils/shuffleArray';
import CardType from '../typescript/CardType';
import AirtableCardType from '../typescript/AirtableCardType';

const getExtraCards = async (
  isMounted: boolean,
  setExtraCards: React.Dispatch<React.SetStateAction<undefined | CardType[]>>,
  setIsFetchDone: React.Dispatch<React.SetStateAction<boolean>>,
  user: any
): Promise<void> => {
  const maxCards: number = 20;

  try {
    const cardsTable = getTable(user, 'cards');

    const cardsFromAirtable: any = await cardsTable
      .select({
        sort: [{ field: 'nextAttemptDate', direction: 'asc' }],
        maxRecords: maxCards,
        view: 'For Today'
      })
      .firstPage();

    const cards: AirtableCardType[] = cardsFromAirtable;

    const formattedCards: CardType[] = cards.map((card) => ({
      id: card.id,
      ...card.fields
    }));

    if (isMounted) {
      await shuffleArray(formattedCards);
      setExtraCards(formattedCards);

      setIsFetchDone(true);
    }
  } catch (err) {
    console.error(err);
  }
};

export default getExtraCards;
