import * as React from 'react';
import MainSection from '../MainSection/MainSection';
import Props from './Props';

function DailyStatus({ header, body }: Props) {
  return (
    <MainSection>
      {header}
      {body}
    </MainSection>
  );
}

export default DailyStatus;
