import React from 'react';
import parseAirtableRichText from '../../helpers/parseAirtableRichText';

type Props = {
  correctAnswer: string;
  keywords: string;
};

const CorrectAnswerSection = ({ correctAnswer, keywords }: Props) => {
  const html = parseAirtableRichText(correctAnswer, []);

  return (
    <>
      <p>
        <strong>Correct Answer:</strong>
      </p>

      <div dangerouslySetInnerHTML={{ __html: html }} />

      {keywords ? (
        <p>
          <strong>Keywords:</strong> {keywords}
        </p>
      ) : null}
    </>
  );
};

export default CorrectAnswerSection;
