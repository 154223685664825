import * as React from 'react';
import parseAirtableRichText from '../../helpers/parseAirtableRichText';
import CardType from '../../typescript/CardType';
import ImageType from '../../typescript/ImageType';

type Props = {
  card: CardType;
  images?: ImageType[];
};

const CardBody = ({ card, images }: Props) => {
  const html = parseAirtableRichText(card.body, images);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default CardBody;
