import { format } from 'date-fns';
import getTable from '../helpers/getTable';

const getAttemptsCountForToday = async (
  isMounted: boolean,
  setAttemptsCountForToday: React.Dispatch<React.SetStateAction<undefined | number>>,
  user: any
): Promise<void> => {
  try {
    const dateStringOfToday: string = format(new Date(), 'yyyy-MM-dd');

    const attemptsTable = getTable(user, 'attempts');

    const attempts = await attemptsTable
      .select({
        filterByFormula: `"${dateStringOfToday}" = DATETIME_FORMAT({dateTexas}, "YYYY-MM-DD")`,
        view: 'Grid view'
      })
      .firstPage();

    if (isMounted) setAttemptsCountForToday(attempts.length);
  } catch (err) {
    console.error(err);
  }
};

export default getAttemptsCountForToday;
