import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import TooltipForButton from '../TooltipForButton/TooltipForButton';

type Props = {
  isSubmitButtonSpinnerDisplayed: boolean;
  isTextAreaDisplayed: boolean;
};

const UserAnswerFormSubmitButton = ({
  isSubmitButtonSpinnerDisplayed,
  isTextAreaDisplayed
}: Props) => {
  const button = (
    <button
      type="submit"
      className="btn btn-primary mr-2 hover:bg-blue-600 w-36"
      disabled={isSubmitButtonSpinnerDisplayed ? true : false}
    >
      {isSubmitButtonSpinnerDisplayed ? <ClipLoader size={15} /> : <>Submit</>}
    </button>
  );

  return isTextAreaDisplayed ? (
    <TooltipForButton text="Ctrl + Enter">{button}</TooltipForButton>
  ) : (
    button
  );
};

export default UserAnswerFormSubmitButton;
