import React, { Fragment, useCallback, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { IoEllipsisVertical } from 'react-icons/io5';
import TooltipForButton from '../TooltipForButton/TooltipForButton';

const UserAnswerFormPopover = () => {
  const handleArchiveCard = useCallback(async () => {
    console.log('archive card 🗃️');
  }, []);

  useEffect(() => {
    const handleArchiveKeyboardShortcut = (event: KeyboardEvent) => {
      if (event.altKey && event.key === 'a') {
        handleArchiveCard();
      }
    };

    document.addEventListener('keydown', handleArchiveKeyboardShortcut);

    return () => {
      document.removeEventListener('keydown', handleArchiveKeyboardShortcut);
    };
  }, [handleArchiveCard]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? 'bg-blue-100' : 'text-opacity-90'}
                group inline-flex items-center rounded btn btn-primary bg-white px-1 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            title="More Options"
          >
            <IoEllipsisVertical className="h-5 w-5 text-xl text-gray-500 hover:text-black" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 w-30 z-10 -translate-x-[77%] sm:-translate-x-[85%] -translate-y-[180%] transform px-4 sm:px-0">
              <div className="overflow-visible rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-white p-3 flex flex-col">
                  <TooltipForButton text="Alt + A">
                    <button
                      className="flex px-2 py-1 rounded hover:bg-gray-100 mx-1"
                      onClick={(e) => {
                        e.preventDefault();
                        handleArchiveCard();
                      }}
                    >
                      Archive
                    </button>
                  </TooltipForButton>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default UserAnswerFormPopover;
