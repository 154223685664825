import React from 'react';
import MainSectionHeader from '../MainSectionHeader/MainSectionHeader';
import AlertForCorrectAnswer from '../AlertForCorrectAnswer/AlertForCorrectAnswer';
import MainSectionHeaderActions from '../MainSectionHeaderActions/MainSectionHeaderActions';
import LinksInCardHeader from '../LinksInCardHeader/LinksInCardHeader';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';

type Props = {
  alertMessageForCorrectAnswer: null | AlertMessageForCorrectAnswerType;
  setAlertMessageForCorrectAnswer: React.Dispatch<
    React.SetStateAction<null | AlertMessageForCorrectAnswerType>
  >;
  user: any;
};

function DailyStatusHeader({
  alertMessageForCorrectAnswer,
  setAlertMessageForCorrectAnswer,
  user
}: Props) {
  return (
    <MainSectionHeader>
      <div>
        Your Progress for Today 📈{' '}
        {alertMessageForCorrectAnswer && (
          <AlertForCorrectAnswer
            alertMessageForCorrectAnswer={alertMessageForCorrectAnswer}
            setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
          />
        )}
      </div>
      <MainSectionHeaderActions user={user}>
        <LinksInCardHeader />
      </MainSectionHeaderActions>
    </MainSectionHeader>
  );
}

export default DailyStatusHeader;
