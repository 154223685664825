import getTable from '../helpers/getTable';
import shuffleArray from '../utils/shuffleArray';
import AirtableCardType from '../typescript/AirtableCardType';
import CardType from '../typescript/CardType';

const getCards = async (
  isMounted: boolean,
  setCards: React.Dispatch<React.SetStateAction<undefined | CardType[]>>,
  attemptsCountForToday: number,
  user: any
): Promise<void> => {
  try {
    const cardsTable = getTable(user, 'cards');

    const dailyAttemptsGoal = 20;

    const maxCards: number = dailyAttemptsGoal - attemptsCountForToday;

    if (maxCards <= 0) {
      setCards([]);
    } else {
      const cardsFromAirtable: any = await cardsTable
        .select({
          maxRecords: maxCards,
          view: 'For Today'
        })
        .firstPage();

      const cards: CardType[] = cardsFromAirtable.map((card: AirtableCardType) => ({
        id: card.id,
        ...card.fields
      }));

      if (isMounted) {
        await shuffleArray(cards);
        setCards(cards);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export default getCards;
