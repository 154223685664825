import * as React from 'react';
import { UserButton } from '@clerk/clerk-react';

function PublicHeader() {
  return (
    <div className="bg-gray-50 border-b border-solid border-bottom-gray-100 flex flex-row flex-nowrap justify-start items-center relative py-2 px-4">
      <span
        style={{ lineHeight: 'inherit' }}
        className="text-black inline-block py-1.5 mr-4 text-xl whitespace-nowrap"
      >
        <a href="/">🧠 Spaced Reps</a>
      </span>
      <div className="flex basis-auto justify-end grow items-center">
        <span className="inline-block py-2">
          <UserButton afterSignOutUrl="/" />
        </span>
      </div>
    </div>
  );
}

export default PublicHeader;
