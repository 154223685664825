import * as React from 'react';

type Props = {
  children: any;
};

const MainSectionHeader = ({ children }: Props) => {
  return (
    <div
      style={{
        borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'
      }}
      className="flex flex-col md:flex-row justify-between py-3 px-5 mb-0 bg-gray-50 border-b border-solid border-gray-300"
    >
      {children}
    </div>
  );
};

export default MainSectionHeader;
