import getTable from '../helpers/getTable';
import CardType from '../typescript/CardType';

const deleteMostRecentAttemptOfCard = async (card: CardType, user: any) => {
  try {
    const attemptsTable = getTable(user, 'attempts');

    let attemptsFromAirtable: any = await attemptsTable
      .select({
        filterByFormula: `${card._id} = ARRAYJOIN({card_id},',')`,
        sort: [{ field: 'date', direction: 'desc' }],
        maxRecords: 1
      })
      .firstPage();

    const mostRecentAttemptFromAirtable = attemptsFromAirtable[0];

    await attemptsTable.destroy([mostRecentAttemptFromAirtable.id]);
  } catch (err) {
    console.error(err);
  }
};

export default deleteMostRecentAttemptOfCard;
