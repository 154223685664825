import hljs from 'highlight.js/lib/common';
import 'highlight.js/styles/github.css';
import '../styles/airtableRichText.css';
import convertMarkdownLinksToHTML from '../utils/convertMarkdownLinksToHTML';
import ImageType from '../typescript/ImageType';

const parseAirtableRichText = (richText: string, images?: ImageType[]) => {
  const lines = richText.split('\n'); // Split the string into lines

  let output = ''; // Create an empty string to store the parsed output

  // Loop through each line of the string
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    // Check for multiline code blocks (triple backticks)
    if (line.startsWith('```')) {
      let code = '';
      i++;

      // Append lines until we find the closing triple backticks
      while (i < lines.length && !lines[i].startsWith('```')) {
        code += lines[i] + '\n';
        i++;
      }

      // Add the code block to the output with syntax highlighting
      output +=
        '<div class="code-block"><pre><code>' +
        hljs.highlightAuto(code).value +
        '</code></pre></div>';
      // Check for single line that is all code
    } else if (line.startsWith('`') && line.endsWith('`')) {
      const regex = /^`(.*)`$/;
      const newLine = line.replace(regex, '$1');
      output += `<div class="code-block"><pre><code>${
        hljs.highlightAuto(newLine).value
      }</code></pre></div>`;
    } else if (line.trim() !== '') {
      // Handle regular text including inline code
      output += '<p>';

      let codeOpen = false;
      let boldOpen = false;
      let italicOpen = false;

      let segment = '';

      for (let j = 0; j < line.length; j++) {
        const char = line[j];

        if (char === '`') {
          if (codeOpen) {
            output += `<code class="inline-slack">${
              hljs.highlightAuto(segment).value
            }</code>`;
          } else {
            output += segment;
          }

          segment = '';

          codeOpen = !codeOpen;
        } else if (char === '*' && line[j + 1] === '*' && !codeOpen) {
          if (boldOpen) {
            output += `<strong>${segment}</strong>`;
          } else {
            output += segment;
          }

          segment = '';

          boldOpen = !boldOpen;

          j++; // skip the second asterisk
        } else if (char === '\\' && line[j + 1] === '_') {
          continue; // skip the backslash (in other words, don't add it to the output)
        } else if (char === '_' && line[j - 1] !== '\\' && !codeOpen) {
          if (italicOpen) {
            output += `<em>${segment}</em>`;
          } else {
            output += segment;
          }

          segment = '';

          italicOpen = !italicOpen;
        } else {
          segment += char;
        }
      }

      output += segment;

      output += '</p>';
    }
  }

  if (images) {
    images.forEach((image, i) => {
      output = output.replace(
        `<p>IMG${i + 1}</p>`,
        `<div style="text-align: center;">Loading image...</div>
        <img class="airtable" src="${image.Image[0].url}"  onload="this.style.display='block'; this.previousElementSibling.style.display='none';" style="display: none;" />`
      );
    });
  } else {
    output = output.replace(
      /<p>IMG\d+<\/p>/g,
      `<div style="text-align: center;">Loading image...</div>`
    );
  }

  // Parse links
  output = convertMarkdownLinksToHTML(output);

  return output;
};

export default parseAirtableRichText;
