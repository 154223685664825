import { addDays, addHours } from 'date-fns';
import getAttemptsByCardId from '../getAttemptsByCardId';
import calculateMostRecentCountOfConsecutiveCorrectAttempts from './calculateMostRecentCountOfConsecutiveCorrectAttempts';
import calculatenumberOfDaysToAdd from './calculateNumberOfDaysToAdd';
import formatDateInAirtableFormat from '../../utils/dates/formatDateInAirtableFormat';

// OLD http://www.blueraja.com/blog/477/a-better-spaced-repetition-learning-algorithm-sm2
// CURRENT https://www.executeprogram.com/spaced-repetition

const calculateNextAttemptDate = async (cardId: string, result: boolean, user: any) => {
  const attempts = await getAttemptsByCardId(cardId, user);

  // This does not include the current attempt.
  const mostRecentCountOfConsecutiveCorrectAttempts =
    calculateMostRecentCountOfConsecutiveCorrectAttempts({
      attempts: attempts ? attempts : []
    });

  const numberOfDaysToAdd = calculatenumberOfDaysToAdd(
    mostRecentCountOfConsecutiveCorrectAttempts,
    result
  );

  const todayDate = new Date();

  let nextAttemptDate: Date;

  if (numberOfDaysToAdd !== 1.1) {
    nextAttemptDate = addDays(todayDate, numberOfDaysToAdd);
  } else {
    // This does not work when using Airtable as the database.
    nextAttemptDate = addHours(todayDate, 25);
  }

  return formatDateInAirtableFormat(nextAttemptDate);
};

export default calculateNextAttemptDate;
