import React from 'react';
import parseAirtableRichText from '../../helpers/parseAirtableRichText';

type Props = {
  answerNotes?: string;
};

const CardToReviewAnswerNotes = ({ answerNotes }: Props) => {
  if (!answerNotes?.trim()) return null;

  const html = parseAirtableRichText(answerNotes, []);

  return (
    <>
      <hr />

      <div style={{ marginBottom: '1.5rem' }}>
        <p>
          <strong>Notes:</strong>
        </p>

        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </>
  );
};

export default CardToReviewAnswerNotes;
