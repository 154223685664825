import AttemptType from '../../typescript/AttemptType';

type Props = {
  attempts: AttemptType[];
};

const calculateMostRecentCountOfConsecutiveCorrectAttempts = ({
  attempts
}: Props): number => {
  let result = 0;

  for (let i = 0; i < attempts.length; i++) {
    const attempt = attempts[i];

    if (attempt.result) {
      result += 1;
    } else {
      break;
    }
  }

  return result;
};

export default calculateMostRecentCountOfConsecutiveCorrectAttempts;
