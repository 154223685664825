import React, { useState, useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';

type Props = {
  alertMessageForCorrectAnswer: AlertMessageForCorrectAnswerType;
  setAlertMessageForCorrectAnswer: React.Dispatch<
    React.SetStateAction<null | AlertMessageForCorrectAnswerType>
  >;
};

const AlertMessage = ({
  alertMessageForCorrectAnswer,
  setAlertMessageForCorrectAnswer
}: Props): JSX.Element | null => {
  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    setTimeout(() => setOpacity(1), 10);

    setTimeout(() => {
      setOpacity(0);

      setTimeout(() => {
        setAlertMessageForCorrectAnswer(null);
      }, 1000);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      style={{
        transition: 'opacity 1s ease',
        opacity
      }}
      className="ml-2.5 text-sm px-2 bg-green-50 text-green-700 border border-solid border-green-400 rounded-md"
    >
      <FiCheck /> <strong>{alertMessageForCorrectAnswer}</strong>
    </span>
  );
};

export default AlertMessage;
