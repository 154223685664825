import Props from './Props';

const submitAttemptForComparisonCard = async ({
  setAlertMessageForCorrectAnswer,
  setIsFormDisplayed,
  setIsSubmitButtonSpinnerDisplayed,
  setResult
}: Props) => {
  setIsSubmitButtonSpinnerDisplayed(true);

  setIsFormDisplayed(false);

  setAlertMessageForCorrectAnswer(null);

  setResult(undefined);
};

export default submitAttemptForComparisonCard;
