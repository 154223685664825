import * as React from 'react';
import { useUser, UserButton } from '@clerk/clerk-react';
import { FiList } from 'react-icons/fi';
import getBaseId from '../../helpers/getBaseId';
import hasAuthorizedEmailForDevelopment from '../../helpers/auth/hasAuthorizedEmailForDevelopment';
import hasAuthorizedEmailForProduction from '../../helpers/auth/hasAuthorizedEmailForProduction';

const LinksInCardHeader = () => {
  const { user } = useUser();
  const baseId = getBaseId(user);

  let cardsTableIdInAirtable: string | undefined;
  let viewIdInAirtable: string | undefined;

  if (hasAuthorizedEmailForDevelopment(user)) {
    cardsTableIdInAirtable = process.env.REACT_APP_AIRTABLE_DEV_TABLE_ID_FOR_CARDS;
    viewIdInAirtable = process.env.REACT_APP_AIRTABLE_DEV_VIEW_ID_FOR_READ;
  } else if (hasAuthorizedEmailForProduction(user)) {
    cardsTableIdInAirtable = process.env.REACT_APP_AIRTABLE_PROD_TABLE_ID_FOR_CARDS;
    viewIdInAirtable = process.env.REACT_APP_AIRTABLE_PROD_VIEW_ID_FOR_READ;
  }

  return (
    <>
      <a
        href={`https://airtable.com/${baseId}/${cardsTableIdInAirtable}/${viewIdInAirtable}?blocks=hide`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FiList />
      </a>
      <UserButton
        afterSignOutUrl="/"
        appearance={{
          elements: {
            userButtonBox: {
              height: '1rem',
              paddingLeft: '0.5rem'
            }
          }
        }}
      />
    </>
  );
};

export default LinksInCardHeader;
