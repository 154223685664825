const calculatenumberOfDaysToAddBeforeFuzzing = (
  mostRecentCountOfConsecutiveCorrectAttempts: number,
  result: boolean | undefined
) => {
  const currentLevel = mostRecentCountOfConsecutiveCorrectAttempts;

  let newLevel: number;

  if (result) {
    // round down to the nearest integer
    newLevel = Math.floor(currentLevel + 1);
  } else {
    switch (currentLevel) {
      case 0:
        newLevel = 0;
        break;

      case 1:
        newLevel = 0;
        break;

      default:
        newLevel = currentLevel / 2;
        break;
    }
  }

  let numberOfDaysToAddBeforeFuzzing = 4 ** (newLevel - 1);

  switch (numberOfDaysToAddBeforeFuzzing) {
    // I'm doing this because it would be the same date on Airtable. I'm not using time on Airtable.
    case 0.25:
      numberOfDaysToAddBeforeFuzzing = 1;
      break;

    case 1:
      numberOfDaysToAddBeforeFuzzing = 1.1;
      break;

    default:
      break;
  }

  return numberOfDaysToAddBeforeFuzzing;
};

export default calculatenumberOfDaysToAddBeforeFuzzing;
