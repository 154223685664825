import AlertMessageForCorrectAnswerType from '../typescript/AlertMessageForCorrectAnswerType';

const createAlertMessageForCorrectAnswer = (): AlertMessageForCorrectAnswerType => {
  let alertMessageForCorrectAnswer: AlertMessageForCorrectAnswerType;

  const lastAlertMessageForSuccessfulTry:
    | undefined
    | 'Correct!'
    | "That's right!"
    | 'You got it!' = sessionStorage.lastAlertMessageForSuccessfulTry;

  if (lastAlertMessageForSuccessfulTry) {
    switch (lastAlertMessageForSuccessfulTry) {
      case 'Correct!':
        alertMessageForCorrectAnswer = "That's right!";
        break;

      case "That's right!":
        alertMessageForCorrectAnswer = 'You got it!';
        break;

      case 'You got it!':
        alertMessageForCorrectAnswer = 'Correct!';
        break;
    }
  } else {
    alertMessageForCorrectAnswer = 'Correct!';
  }

  return alertMessageForCorrectAnswer;
};

export default createAlertMessageForCorrectAnswer;
