// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match

const calculateLineBreakCountOfAnswer = (cardAnswer: string): number => {
  const lineBreaks: RegExpMatchArray | null = cardAnswer.match(/\n/g);

  let lineBreakCount: number = lineBreaks ? lineBreaks.length : 0;

  if (cardAnswer.includes('```')) {
    lineBreakCount -= 2;
  }

  return lineBreakCount;
};

export default calculateLineBreakCountOfAnswer;
