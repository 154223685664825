import CardType from '../typescript/CardType';

const calculateResult = (
  card: CardType,
  userAnswer: string,
  lineBreakCountOfAnswer: number
): boolean => {
  let userAnswerTrimmed = userAnswer.trim();
  let correctAnswerTrimmed = card.answer.trim();

  if (correctAnswerTrimmed.toLowerCase() === 'yes') {
    if (userAnswerTrimmed.toLowerCase() === 'y') {
      return true;
    }
  }

  if (correctAnswerTrimmed.toLowerCase() === 'no') {
    if (userAnswerTrimmed.toLowerCase() === 'n') {
      return true;
    }
  }

  if (
    correctAnswerTrimmed.toLowerCase() === 'true' ||
    correctAnswerTrimmed.toLowerCase() === '`true`'
  ) {
    if (userAnswerTrimmed.toLowerCase() === 't') {
      return true;
    }
  }

  if (
    correctAnswerTrimmed.toLowerCase() === 'false' ||
    correctAnswerTrimmed.toLowerCase() === '`false`'
  ) {
    if (userAnswerTrimmed.toLowerCase() === 'f') {
      return true;
    }
  }

  if (card.answerType === 'exact') {
    if (!card.isCaseSensitive) {
      correctAnswerTrimmed = correctAnswerTrimmed.toLowerCase();
      userAnswerTrimmed = userAnswerTrimmed.toLowerCase();
    }

    correctAnswerTrimmed = correctAnswerTrimmed.replace(/`/g, '');

    correctAnswerTrimmed = correctAnswerTrimmed.replace('\\[', '[');
    correctAnswerTrimmed = correctAnswerTrimmed.replace(']\\', ']');

    correctAnswerTrimmed = correctAnswerTrimmed.replace(/&lt;/g, '<');

    if (card.hasHtmlCode) {
      correctAnswerTrimmed = correctAnswerTrimmed.replace('`', '');
    }

    if (lineBreakCountOfAnswer > 1 || card.answerStyle === 'code') {
      userAnswerTrimmed = userAnswerTrimmed.replace(/\s/g, '');
      correctAnswerTrimmed = correctAnswerTrimmed.replace(/\s/g, '');
    }

    if (userAnswerTrimmed === correctAnswerTrimmed) {
      return true;
    }
  }

  if (card.answerType === 'keywords') {
    if (!card.isCaseSensitive) {
      userAnswerTrimmed = userAnswerTrimmed.toLowerCase();
    }

    let result = true;
    const keywords = card.keywords.split(',');

    keywords.forEach((k) => {
      let keyword = k.trim();

      if (keyword.includes('OR')) {
        const keywordsOr = keyword.split('OR');

        let resultOr = false;

        keywordsOr.forEach((kOr) => {
          let keywordOr = kOr.trim();

          if (!card.isCaseSensitive) {
            keywordOr = keywordOr.toLowerCase();
          }

          if (userAnswerTrimmed.includes(keywordOr)) {
            resultOr = true;
            return;
          }
        });

        if (!resultOr) {
          result = false;
          return;
        }
      }

      if (!card.isCaseSensitive) {
        keyword = keyword.toLowerCase();
      }

      if (!userAnswerTrimmed.includes(keyword)) {
        result = false;
        return;
      }
    });

    return result;
  }

  return false;
};

export default calculateResult;
