import React from 'react';

export default function TooltipForButton({
  text,
  children
}: {
  text: string;
  children: React.ReactNode;
}) {
  return (
    <div className="tooltip-container inline-grid justify-items-center relative">
      {children}
      <div className="tooltip absolute invisible -top-8 rounded text-white bg-gray-700 text-sm px-2 py-1">
        {text}
      </div>
    </div>
  );
}
