import React, { useState, useEffect, useCallback } from 'react';
import submitAttempt from '../../data/submitAttempt/submitAttempt';
import submitAttemptForComparisonCard from '../../data/submitAttemptForComparisonCard/submitAttemptForComparisonCard';
import chooseRandomIntBetweenTwoNumbersInclusive from '../../utils/chooseRandomIntBetweenTwoNumbersInclusive';
import TooltipForButton from '../TooltipForButton/TooltipForButton';
import UserAnswerFormPopover from './UserAnswerFormPopover';
import UserAnswerFormSubmitButton from './UserAnswerFormSubmitButton';
import CardType from '../../typescript/CardType';
import Props from './Props';

const randomIntBetweenZeroAndTwo = chooseRandomIntBetweenTwoNumbersInclusive(0, 2);

const UserAnswerForm = ({
  card,
  lineBreakCountOfAnswer,
  setAlertMessageForCorrectAnswer,
  setAttemptsCountForToday,
  setCardIndex,
  setIncorrectCards,
  setIsFormDisplayed,
  setResult,
  setSaveStatus,
  setUserAnswer,
  setWasShowAnswerButtonClicked,
  userAnswer,
  user
}: Props) => {
  const [isSubmitButtonSpinnerDisplayed, setIsSubmitButtonSpinnerDisplayed] =
    useState<boolean>(false);

  const fontFamily =
    card.answerStyle === 'code' || card.answerStyle === 'htmlCode' ? 'font-mono' : '';

  const isTextareaDisplayed = lineBreakCountOfAnswer > 1;

  const handleShowAnswer = useCallback(() => {
    setWasShowAnswerButtonClicked(true);

    submitAttemptForComparisonCard({
      setAlertMessageForCorrectAnswer,
      setIsFormDisplayed,
      setIsSubmitButtonSpinnerDisplayed,
      setResult
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleShowAnswerKeyboardShortcut = (event: KeyboardEvent) => {
      if (event.altKey && event.key === 's') {
        handleShowAnswer();
      }
    };

    document.addEventListener('keydown', handleShowAnswerKeyboardShortcut);

    return () => {
      document.removeEventListener('keydown', handleShowAnswerKeyboardShortcut);
    };
  }, [handleShowAnswer]);

  const handleSubmit = useCallback(
    (
      card: CardType,
      e: any,
      lineBreakCountOfAnswer: number,
      userAnswer: string,
      user: any
    ) => {
      e.preventDefault();

      if (card.answerType === 'exact' || card.answerType === 'keywords') {
        submitAttempt({
          card,
          lineBreakCountOfAnswer,
          setAlertMessageForCorrectAnswer,
          setAttemptsCountForToday,
          setCardIndex,
          setIncorrectCards,
          setIsFormDisplayed,
          setIsSubmitButtonSpinnerDisplayed,
          setResult,
          setSaveStatus,
          setUserAnswer,
          userAnswer,
          user
        });
      } else if (card.answerType === 'comparison') {
        submitAttemptForComparisonCard({
          setAlertMessageForCorrectAnswer,
          setIsFormDisplayed,
          setIsSubmitButtonSpinnerDisplayed,
          setResult
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [card, lineBreakCountOfAnswer, userAnswer, user]
  );

  useEffect(() => {
    const handleSubmitKeyboardShortcut = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        handleSubmit(card, event, lineBreakCountOfAnswer, userAnswer, user);
      }
    };

    document.addEventListener('keydown', handleSubmitKeyboardShortcut);

    return () => {
      document.removeEventListener('keydown', handleSubmitKeyboardShortcut);
    };
  }, [card, handleSubmit, lineBreakCountOfAnswer, userAnswer, user]);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(card, e, lineBreakCountOfAnswer, userAnswer, user);
      }}
      autoComplete="off"
    >
      <div className="mb-4">
        <label htmlFor="answer" className="inline-block mb-2">
          Your Answer
        </label>

        {isTextareaDisplayed ? (
          <textarea
            name="name"
            rows={lineBreakCountOfAnswer + randomIntBetweenZeroAndTwo}
            className={`form-control ${fontFamily}`}
            onChange={(e) => setUserAnswer(e.target.value)}
            spellCheck="false"
            value={userAnswer}
            autoFocus
          />
        ) : (
          <input
            type="text"
            name="name"
            value={userAnswer}
            className={`form-control ${fontFamily}`}
            onChange={(e) => setUserAnswer(e.target.value)}
            spellCheck="false"
            autoFocus
          />
        )}
      </div>

      <div className="flex flex-row">
        <UserAnswerFormSubmitButton
          isSubmitButtonSpinnerDisplayed={isSubmitButtonSpinnerDisplayed}
          isTextAreaDisplayed={isTextareaDisplayed}
        />

        {card.answerType === 'comparison' && (
          <TooltipForButton text="Shift + Ctrl + S">
            <button
              type="button"
              className="btn bg-green-600 hover:bg-green-700 text-white w-36"
              onClick={() => {
                handleShowAnswer();
              }}
            >
              Show Answer
            </button>
          </TooltipForButton>
        )}

        <UserAnswerFormPopover />
      </div>
    </form>
  );
};

export default UserAnswerForm;
