import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import getExtraCards from '../../data/getExtraCards';
import ExtraCreditButton from './ExtraCreditButton';
import CardType from '../../typescript/CardType';
import SaveStatusType from '../../typescript/SaveStatusType';

type Props = {
  attemptsCountForToday: number;
  saveStatus: SaveStatusType;
  setCards: React.Dispatch<React.SetStateAction<CardType[] | undefined>>;
  user: any;
};

const ExtraCredit = ({ attemptsCountForToday, saveStatus, setCards, user }: Props) => {
  const [isFetchDone, setIsFetchDone] = useState<boolean>(false);
  const [extraCards, setExtraCards] = useState<undefined | CardType[]>(undefined);

  const dailyAttemptsGoal = 20;

  useEffect(() => {
    let isMounted: boolean = true;

    if (attemptsCountForToday >= dailyAttemptsGoal && saveStatus === 'idle')
      getExtraCards(isMounted, setExtraCards, setIsFetchDone, user);

    return () => {
      isMounted = false;
    };
  }, [attemptsCountForToday, saveStatus, setCards, user]);

  return (
    <>
      <hr />
      <div className="flex flex-col">
        {attemptsCountForToday >= dailyAttemptsGoal && !isFetchDone && (
          <span>
            <ClipLoader color="#666" size={15} />
          </span>
        )}

        {(attemptsCountForToday < dailyAttemptsGoal ||
          (isFetchDone && extraCards?.length === 0)) && (
          <p className="mb-1">There are no more cards to review.</p>
        )}

        {attemptsCountForToday >= dailyAttemptsGoal &&
          isFetchDone &&
          extraCards &&
          extraCards?.length > 0 && (
            <div className="flex flex-col">
              <p>
                If you have <strong>free time</strong>, you can review more cards by
                clicking below.
              </p>
              <ExtraCreditButton extraCards={extraCards} setCards={setCards} />
            </div>
          )}
      </div>
    </>
  );
};

export default ExtraCredit;
