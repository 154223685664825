import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import ClipLoader from 'react-spinners/ClipLoader';
import hasAuthorizedEmailForDevelopment from '../../helpers/auth/hasAuthorizedEmailForDevelopment';
import hasAuthorizedEmailForProduction from '../../helpers/auth/hasAuthorizedEmailForProduction';
import getTable from '../../helpers/getTable';
import { newCards } from './DatabaseResetProvider.data';
import AirtableAttemptType from '../../typescript/AirtableAttemptType';
import AttemptType from '../../typescript/AttemptType';
import AirtableCardType from '../../typescript/AirtableCardType';
import CardType from '../../typescript/CardType';

function DatabaseResetProvider({ children }: { children: React.ReactNode }) {
  const { user } = useUser();

  const [attempts, setAttempts] = useState<AttemptType[]>([]);
  const [wereOldAttemptsFetched, setWereOldAttemptsFetched] = useState<boolean>(false);
  const [wereOldAttemptsDeleted, setWereOldAttemptsDeleted] = useState<boolean>(false);

  const [cards, setCards] = useState<CardType[]>([]);
  const [wereOldCardsFetched, setWereOldCardsFetched] = useState<boolean>(false);
  const [wereOldCardsDeleted, setWereOldCardsDeleted] = useState<boolean>(false);
  const [wereTestCardsCreated, setWereTestCardsCreated] = useState<boolean>(false);

  useEffect(() => {
    let isMounted: boolean = true;

    if (hasAuthorizedEmailForDevelopment(user)) {
      // Get Tables from Airtable

      const cardsTable: any = getTable(user, 'cards');
      const attemptsTable: any = getTable(user, 'attempts');

      // Helper Async Functions

      const getAttempts = async () => {
        const existingAttemptsFromAirtable: any = await attemptsTable
          .select({
            maxRecords: 100,
            view: 'Grid view'
          })
          .firstPage();

        const existingAttemptsFromAirtableAfterFormatting: AttemptType[] =
          existingAttemptsFromAirtable.map((attempt: AirtableAttemptType) => ({
            id: attempt.id,
            ...attempt.fields
          }));

        if (isMounted) {
          setAttempts(existingAttemptsFromAirtableAfterFormatting);
          setWereOldAttemptsFetched(true);
        }
      };

      const deleteAllOldAttempts = async () => {
        const existingAttemptsIds: string[] = attempts.map((attempts) => attempts.id);

        //  existingAttemptsIds should be separated into chunks of 10
        //  because Airtable has a limit of 10 records per request
        //  when deleting records
        for (let i = 0; i < existingAttemptsIds.length; i += 10) {
          const chunkOfTenAttemptIds = existingAttemptsIds.slice(i, i + 10);
          await attemptsTable.destroy(chunkOfTenAttemptIds);
        }

        if (isMounted) {
          setAttempts([]);
          setWereOldAttemptsDeleted(true);
        }
      };

      const getCards = async () => {
        const existingCardsFromAirtable: any = await cardsTable
          .select({
            maxRecords: 100,
            view: 'All'
          })
          .firstPage();

        const existingCardsFromAirtableAfterFormatting: CardType[] =
          existingCardsFromAirtable.map((card: AirtableCardType) => ({
            id: card.id,
            ...card.fields
          }));

        if (isMounted) {
          setCards(existingCardsFromAirtableAfterFormatting);
          setWereOldCardsFetched(true);
        }
      };

      const deleteAllOldCards = async () => {
        const existingCardsIds: string[] = cards.map((card) => card.id);

        //  existingCardIds should be separated into chunks of 10
        //  because Airtable has a limit of 10 records per request
        //  when deleting records
        for (let i = 0; i < existingCardsIds.length; i += 10) {
          const chunkOfTenCardIds = existingCardsIds.slice(i, i + 10);
          await cardsTable.destroy(chunkOfTenCardIds);
        }

        if (isMounted) {
          setCards([]);
          setWereOldCardsDeleted(true);
        }
      };

      const createNewCards = async () => {
        const newCardsFromAirtable = await cardsTable.create(newCards);

        const newCardsFromAirtableAfterFormatting: CardType[] = newCardsFromAirtable.map(
          (card: AirtableCardType) => ({
            id: card.id,
            ...card.fields
          })
        );

        if (isMounted) {
          setCards(newCardsFromAirtableAfterFormatting);
          setWereTestCardsCreated(true);
        }
      };

      // Wrapper Async Function

      const runHelperAsyncFunctions = async () => {
        if (!wereOldAttemptsFetched) await getAttempts();

        if (attempts.length > 0 && !wereOldAttemptsDeleted) {
          await deleteAllOldAttempts();
        }

        if (!wereOldCardsFetched) await getCards();

        if (cards.length > 0 && !wereOldCardsDeleted) {
          await deleteAllOldCards();
        }

        if (wereOldCardsFetched && cards.length === 0) await createNewCards();
      };

      if (!wereTestCardsCreated) runHelperAsyncFunctions();
    }

    return () => {
      isMounted = false;
    };
  }, [
    attempts,
    cards,
    user,
    wereOldAttemptsDeleted,
    wereOldAttemptsFetched,
    wereOldCardsDeleted,
    wereOldCardsFetched,
    wereTestCardsCreated
  ]);

  if (hasAuthorizedEmailForDevelopment(user)) {
    if (wereTestCardsCreated) {
      return <>{children}</>;
    } else {
      return (
        <div className="flex justify-center mt-10">
          <div className="flex flex-col">
            <p>Deleting all attempts...</p>
            <p>Deleting all cards...</p>
            <p>Creating new cards...</p>
            <p>
              Please wait. 🤖 <ClipLoader color="#666" size={15} />
            </p>
          </div>
        </div>
      );
    }
  }

  if (hasAuthorizedEmailForProduction(user)) return <>{children}</>;

  throw new Error('You are not authorized to view this page.');
}

export default DatabaseResetProvider;
