import React from 'react';
import { useUser } from '@clerk/clerk-react';
import { FiEdit } from 'react-icons/fi';
import getBaseId from '../../helpers/getBaseId';
import hasAuthorizedEmailForDevelopment from '../../helpers/auth/hasAuthorizedEmailForDevelopment';
import hasAuthorizedEmailForProduction from '../../helpers/auth/hasAuthorizedEmailForProduction';
import MainSectionHeader from '../MainSectionHeader/MainSectionHeader';
import AlertForCorrectAnswer from '../AlertForCorrectAnswer/AlertForCorrectAnswer';
import MainSectionHeaderActions from '../MainSectionHeaderActions/MainSectionHeaderActions';
import LinksInCardHeader from '../LinksInCardHeader/LinksInCardHeader';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';

type Props = {
  alertMessageForCorrectAnswer: null | AlertMessageForCorrectAnswerType;
  cardId: string;
  numOfCardsToGo: number;
  setAlertMessageForCorrectAnswer: React.Dispatch<
    React.SetStateAction<null | AlertMessageForCorrectAnswerType>
  >;
};

const CardToReviewHeader = ({
  alertMessageForCorrectAnswer,
  cardId,
  numOfCardsToGo,
  setAlertMessageForCorrectAnswer
}: Props) => {
  const { user } = useUser();
  const baseId = getBaseId(user);

  let cardsTableIdInAirtable: string | undefined;
  let viewIdInAirtable: string | undefined;

  if (hasAuthorizedEmailForDevelopment(user)) {
    cardsTableIdInAirtable = process.env.REACT_APP_AIRTABLE_DEV_TABLE_ID_FOR_CARDS;
    viewIdInAirtable = process.env.REACT_APP_AIRTABLE_DEV_VIEW_ID_FOR_READ;
  } else if (hasAuthorizedEmailForProduction(user)) {
    cardsTableIdInAirtable = process.env.REACT_APP_AIRTABLE_PROD_TABLE_ID_FOR_CARDS;
    viewIdInAirtable = process.env.REACT_APP_AIRTABLE_PROD_VIEW_ID_FOR_READ;
  }

  return (
    <MainSectionHeader>
      <div>
        {numOfCardsToGo} Card{numOfCardsToGo !== 1 ? 's' : ''} to Go
        {alertMessageForCorrectAnswer && (
          <AlertForCorrectAnswer
            alertMessageForCorrectAnswer={alertMessageForCorrectAnswer}
            setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
          />
        )}
      </div>

      <MainSectionHeaderActions user={user}>
        <a
          href={`https://airtable.com/${baseId}/${cardsTableIdInAirtable}/${viewIdInAirtable}/${cardId}?blocks=hide`}
          target="_blank"
          rel="noopener noreferrer"
          className="mr-1.5"
        >
          <FiEdit />
        </a>
        <LinksInCardHeader />
      </MainSectionHeaderActions>
    </MainSectionHeader>
  );
};

export default CardToReviewHeader;
