import React, { useCallback, useEffect } from 'react';
import CardType from '../../typescript/CardType';

type Props = {
  extraCards: CardType[];
  setCards: React.Dispatch<React.SetStateAction<CardType[] | undefined>>;
};

const ExtraCreditButton = ({ extraCards, setCards }: Props) => {
  const handleReviewExtraCards = useCallback(
    (e: any) => {
      e.preventDefault();

      setCards(extraCards);
    },
    [extraCards, setCards]
  );

  useEffect(() => {
    const handleReviewExtraCardsKeyboardShortcut = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleReviewExtraCards(event);
      }
    };

    document.addEventListener('keydown', handleReviewExtraCardsKeyboardShortcut);

    return () => {
      document.removeEventListener('keydown', handleReviewExtraCardsKeyboardShortcut);
    };
  }, [extraCards, handleReviewExtraCards, setCards]);

  return (
    <button
      style={{ width: '8rem' }}
      type="button"
      className="btn btn-primary -mt-2"
      onClick={(e) => {
        handleReviewExtraCards(e);
      }}
    >
      Extra Credit
    </button>
  );
};

export default ExtraCreditButton;
