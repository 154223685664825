import React, { useState, useEffect, useCallback } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import createAttemptAndUpdateCard from '../../data/createAttemptAndUpdateCard';
import markAsCorrect from '../../data/markAsCorrect/markAsCorrect';
import formatUserAnswer from '../../helpers/formatUserAnswer';
import CardToReviewAnswerNotes from './CardToReviewAnswerNotes';
import CardToReviewCorrectAnswer from './CardToReviewCorrectAnswer';
import TooltipForButton from '../TooltipForButton/TooltipForButton';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';
import CardType from '../../typescript/CardType';
import SaveStatusType from '../../typescript/SaveStatusType';

type Props = {
  card: CardType;
  result?: boolean;
  wasShowAnswerButtonClicked: boolean;
  setAlertMessageForCorrectAnswer: React.Dispatch<
    React.SetStateAction<null | AlertMessageForCorrectAnswerType>
  >;
  setAttemptsCountForToday: React.Dispatch<React.SetStateAction<undefined | number>>;
  setCardIndex: React.Dispatch<React.SetStateAction<number>>;
  setIncorrectCards: React.Dispatch<React.SetStateAction<CardType[]>>;
  setIsFormDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  setResult: React.Dispatch<React.SetStateAction<undefined | boolean>>;
  setSaveStatus: React.Dispatch<React.SetStateAction<SaveStatusType>>;
  setUserAnswer: React.Dispatch<React.SetStateAction<string>>;
  setWasShowAnswerButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
  user: any;
  userAnswer: string;
};

const CardToReviewComparison = ({
  card,
  result,
  wasShowAnswerButtonClicked,
  setAlertMessageForCorrectAnswer,
  setAttemptsCountForToday,
  setCardIndex,
  setIncorrectCards,
  setIsFormDisplayed,
  setResult,
  setSaveStatus,
  setUserAnswer,
  setWasShowAnswerButtonClicked,
  user,
  userAnswer
}: Props) => {
  const [
    isButtonSpinnerMarkAsCorrectIsDisplayed,
    setIsButtonSpinnerMarkAsCorrectDisplayed
  ] = useState<boolean>(false);

  const [
    isButtonSpinnerMarkAsIncorrectIsDisplayed,
    setIsButtonSpinnerMarkAsIncorrectDisplayed
  ] = useState<boolean>(false);

  const userAnswerFormatted = formatUserAnswer(card.answerStyle, userAnswer, result);

  const handleMarkAsCorrect = useCallback(
    async (card: CardType, userAnswer: string, user: any) => {
      markAsCorrect({
        answerType: card.answerType,
        card,
        setAlertMessageForCorrectAnswer,
        setAttemptsCountForToday,
        setCardIndex,
        setIncorrectCards,
        setIsButtonSpinnerMarkAsCorrectDisplayed,
        setIsFormDisplayed,
        setResult,
        setSaveStatus,
        setUserAnswer,
        setWasShowAnswerButtonClicked,
        userAnswer,
        user
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [card, userAnswer, user]
  );

  const handleMarkAsIncorrect = useCallback(
    async (card: CardType, userAnswer: string, user: any) => {
      setIsButtonSpinnerMarkAsIncorrectDisplayed(true);

      setWasShowAnswerButtonClicked(false);

      setUserAnswer('');

      setResult(false);

      setCardIndex((prevCardIndex) => prevCardIndex + 1);

      setAlertMessageForCorrectAnswer(null);

      setIsFormDisplayed(true);

      const incorrectCard = {
        ...card,
        isIncorrectCard: true
      };

      setIncorrectCards((prevIncorrectCards) => [...prevIncorrectCards, incorrectCard]);

      if (!card.isIncorrectCard) {
        await createAttemptAndUpdateCard(card, false, userAnswer, setSaveStatus, user);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [card, userAnswer, user]
  );

  useEffect(() => {
    const handleCorrectOrIncorrect = (event: KeyboardEvent) => {
      if (
        (event.key === 'c' || event.key === 'C') &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        event.preventDefault();
        handleMarkAsCorrect(card, userAnswer, user);
      } else if (
        (event.key === 'i' || event.key === 'I') &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        event.preventDefault();
        handleMarkAsIncorrect(card, userAnswer, user);
      }
    };

    document.addEventListener('keydown', handleCorrectOrIncorrect);

    return () => {
      document.removeEventListener('keydown', handleCorrectOrIncorrect);
    };
  }, [card, handleMarkAsCorrect, handleMarkAsIncorrect, user, userAnswer]);

  useEffect(() => {
    setAlertMessageForCorrectAnswer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {userAnswer.trim() && !wasShowAnswerButtonClicked && (
        <p>
          <strong>Your Answer:</strong> {userAnswerFormatted}
        </p>
      )}

      <CardToReviewCorrectAnswer correctAnswer={card.answer} keywords={card.keywords} />

      <CardToReviewAnswerNotes answerNotes={card.answerNotes} />

      <TooltipForButton text="Mark as Correct (C)">
        <button
          style={{ width: '10.4rem' }}
          type="submit"
          className="btn bg-green-600 hover:bg-green-700 text-white w-36 mr-2"
          disabled={isButtonSpinnerMarkAsCorrectIsDisplayed ? true : false}
          onClick={async () => {
            handleMarkAsCorrect(card, userAnswer, user);
          }}
        >
          {isButtonSpinnerMarkAsCorrectIsDisplayed ? (
            <ClipLoader size={15} />
          ) : (
            <>
              Mark as <strong>C</strong>orrect
            </>
          )}
        </button>
      </TooltipForButton>

      <TooltipForButton text="Mark as Incorrect (I)">
        <button
          style={{ width: '10.4rem' }}
          type="submit"
          className="btn bg-red-600 hover:bg-red-700 text-white w-36w-36"
          disabled={isButtonSpinnerMarkAsIncorrectIsDisplayed ? true : false}
          onClick={async () => {
            handleMarkAsIncorrect(card, userAnswer, user);
          }}
        >
          {isButtonSpinnerMarkAsIncorrectIsDisplayed ? (
            <ClipLoader size={15} />
          ) : (
            <>
              Mark as <strong>I</strong>ncorrect
            </>
          )}
        </button>
      </TooltipForButton>
    </div>
  );
};

export default CardToReviewComparison;
