import createAlertMessageForCorrectAnswer from '../../helpers/createAlertMessageForCorrectAnswer';
import deleteMostRecentAttemptOfCard from '../deleteMostRecentAttemptOfCard';
import createAttemptAndUpdateCard from '../createAttemptAndUpdateCard';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';
import Props from './Props';

export default async function markAsCorrect({
  answerType,
  card,
  setAlertMessageForCorrectAnswer,
  setAttemptsCountForToday,
  setCardIndex,
  setIncorrectCards,
  setIsButtonSpinnerMarkAsCorrectDisplayed,
  setIsFormDisplayed,
  setResult,
  setSaveStatus,
  setUserAnswer,
  setWasShowAnswerButtonClicked,
  userAnswer,
  user
}: Props) {
  setIsButtonSpinnerMarkAsCorrectDisplayed(true);

  setWasShowAnswerButtonClicked(false);

  setAttemptsCountForToday((prevAttemptsCountForToday) => {
    if (prevAttemptsCountForToday !== undefined) {
      return prevAttemptsCountForToday + 1;
    } else {
      return undefined;
    }
  });

  setUserAnswer('');

  setResult(true);

  setCardIndex((prevCardIndex) => prevCardIndex + 1);

  const alertMessageForCorrectAnswer: AlertMessageForCorrectAnswerType =
    createAlertMessageForCorrectAnswer();

  setAlertMessageForCorrectAnswer(alertMessageForCorrectAnswer);
  sessionStorage.lastAlertMessageForSuccessfulTry = alertMessageForCorrectAnswer;

  setIsFormDisplayed(true);

  if (answerType === 'exact' || answerType === 'keywords') {
    setIncorrectCards((prevIncorrectCards) => {
      prevIncorrectCards.pop();
      return prevIncorrectCards;
    });

    await deleteMostRecentAttemptOfCard(card, user);

    await createAttemptAndUpdateCard(card, true, userAnswer, setSaveStatus, user);
  } else if (answerType === 'comparison') {
    if (!card.isIncorrectCard) {
      await createAttemptAndUpdateCard(card, true, userAnswer, setSaveStatus, user);
    }
  }
}
