import hasAuthorizedEmailForProduction from './auth/hasAuthorizedEmailForProduction';
import hasAuthorizedEmailForDevelopment from './auth/hasAuthorizedEmailForDevelopment';

const getBaseId = (user: any) => {
  let baseId: string | undefined = '';

  if (hasAuthorizedEmailForProduction(user)) {
    baseId = process.env.REACT_APP_AIRTABLE_PROD_BASE_ID;
  } else if (hasAuthorizedEmailForDevelopment(user)) {
    baseId = process.env.REACT_APP_AIRTABLE_DEV_BASE_ID;
  } else {
    throw new Error("User's email is not authorized for production or development.");
  }

  return baseId;
};

export default getBaseId;
