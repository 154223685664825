import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import ClipLoader from 'react-spinners/ClipLoader';
import getAttemptsCountForToday from '../../data/getAttemptsCountForToday';
import getCards from '../../data/getCards';
import hasAuthorizedEmail from '../../helpers/auth/hasAuthorizedEmail';
import CardToReview from '../../components/CardToReview/CardToReview';
import DailyStatus from '../../components/DailyStatus/DailyStatus';
import DailyStatusHeader from '../../components/DailyStatus/DailyStatusHeader';
import DailyStatusBody from '../../components/DailyStatus/DailyStatusBody';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';
import CardType from '../../typescript/CardType';
import SaveStatusType from '../../typescript/SaveStatusType';

function HomePage() {
  const [alertMessageForCorrectAnswer, setAlertMessageForCorrectAnswer] =
    useState<null | AlertMessageForCorrectAnswerType>(null);
  const [attemptsCountForToday, setAttemptsCountForToday] = useState<undefined | number>(
    undefined
  );
  const [cards, setCards] = useState<undefined | CardType[]>(undefined);
  const [saveStatus, setSaveStatus] = useState<SaveStatusType>('idle');

  const { user } = useUser();

  useEffect(() => {
    let isMounted: boolean = true;

    if (attemptsCountForToday === undefined) {
      getAttemptsCountForToday(isMounted, setAttemptsCountForToday, user);
    } else {
      if (attemptsCountForToday < 20 && cards === undefined) {
        getCards(isMounted, setCards, attemptsCountForToday, user);
      } else if (cards === undefined) {
        setCards([]);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [attemptsCountForToday, cards, user]);

  if (hasAuthorizedEmail(user)) {
    return <div>Your email address is not authorized so there are no cards.</div>;
  }

  if (attemptsCountForToday === undefined || cards === undefined) {
    return (
      <div className="flex justify-center items-center">
        <ClipLoader color="#666" />
      </div>
    );
  }

  if (cards.length > 0) {
    return (
      <CardToReview
        alertMessageForCorrectAnswer={alertMessageForCorrectAnswer}
        cards={cards}
        setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
        setAttemptsCountForToday={setAttemptsCountForToday}
        setCards={setCards}
        setSaveStatus={setSaveStatus}
        user={user}
      />
    );
  }

  return (
    <DailyStatus
      header={
        <DailyStatusHeader
          alertMessageForCorrectAnswer={alertMessageForCorrectAnswer}
          setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
          user={user}
        />
      }
      body={
        <DailyStatusBody
          attemptsCountForToday={attemptsCountForToday}
          saveStatus={saveStatus}
          setCards={setCards}
          user={user}
        />
      }
    />
  );
}

export default HomePage;
