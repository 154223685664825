import calculateResult from '../../helpers/calculateResult';
import createAlertMessageForCorrectAnswer from '../../helpers/createAlertMessageForCorrectAnswer';
import createAttemptAndUpdateCard from '../createAttemptAndUpdateCard';
import AlertMessageForCorrectAnswerType from '../../typescript/AlertMessageForCorrectAnswerType';
import Props from './Props';

const submitAttempt = async ({
  card,
  lineBreakCountOfAnswer,
  setAlertMessageForCorrectAnswer,
  setAttemptsCountForToday,
  setCardIndex,
  setIncorrectCards,
  setIsFormDisplayed,
  setIsSubmitButtonSpinnerDisplayed,
  setResult,
  setSaveStatus,
  setUserAnswer,
  userAnswer,
  user
}: Props) => {
  setIsSubmitButtonSpinnerDisplayed(true);

  const result = calculateResult(card, userAnswer, lineBreakCountOfAnswer);

  if (result) {
    setAttemptsCountForToday((prevAttemptsCountForToday) => {
      if (prevAttemptsCountForToday !== undefined) {
        return prevAttemptsCountForToday + 1;
      } else {
        return undefined;
      }
    });

    if (!card.answerNotes?.trim()) {
      setUserAnswer('');

      const alertMessageForCorrectAnswer: AlertMessageForCorrectAnswerType =
        createAlertMessageForCorrectAnswer();

      setAlertMessageForCorrectAnswer(alertMessageForCorrectAnswer);
      sessionStorage.lastAlertMessageForSuccessfulTry = alertMessageForCorrectAnswer;

      setCardIndex((prevCardIndex) => prevCardIndex + 1);
    } else {
      setIsFormDisplayed(false);
    }

    setIsSubmitButtonSpinnerDisplayed(false);
  } else {
    setIsFormDisplayed(false);

    setAlertMessageForCorrectAnswer(null);

    setIsSubmitButtonSpinnerDisplayed(false);

    const incorrectCard = {
      ...card,
      isIncorrectCard: true
    };

    setIncorrectCards((prevIncorrectCards) => [...prevIncorrectCards, incorrectCard]);
  }

  setResult(result);

  if (!card.isIncorrectCard) {
    await createAttemptAndUpdateCard(card, result, userAnswer, setSaveStatus, user);
  }
};

export default submitAttempt;
