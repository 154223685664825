import React, { useState, useEffect, useCallback, useRef } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { FaThumbsUp } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import markAsCorrect from '../../data/markAsCorrect/markAsCorrect';
import formatUserAnswer from '../../helpers/formatUserAnswer';
import CardToReviewAnswerNotes from './CardToReviewAnswerNotes';
import CardToReviewCorrectAnswer from './CardToReviewCorrectAnswer';
import TooltipForButton from '../TooltipForButton/TooltipForButton';
import CardType from '../../typescript/CardType';
import Props from './CardToReviewResult.props';

const Result = ({
  card,
  result,
  setAlertMessageForCorrectAnswer,
  setAttemptsCountForToday,
  setCardIndex,
  setIncorrectCards,
  setIsFormDisplayed,
  setResult,
  setSaveStatus,
  setUserAnswer,
  setWasShowAnswerButtonClicked,
  user,
  userAnswer
}: Props) => {
  const [isButtonSpinnerIsDisplayed, setIsButtonSpinnerDisplayed] =
    useState<boolean>(false);
  const [
    isButtonSpinnerMarkAsCorrectIsDisplayed,
    setIsButtonSpinnerMarkAsCorrectDisplayed
  ] = useState<boolean>(false);

  const topDivRef = useRef<HTMLDivElement>(null);

  const userAnswerFormatted = formatUserAnswer(card.answerStyle, userAnswer, result);

  const handleGoToNextCard = useCallback((e: any) => {
    e.preventDefault();

    setIsButtonSpinnerDisplayed(true);

    setCardIndex((prevCardIndex) => prevCardIndex + 1);

    setUserAnswer('');

    setResult(undefined);

    setIsFormDisplayed(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarkAsCorrect = useCallback(
    async (card: CardType, userAnswer: string, user: any) => {
      markAsCorrect({
        answerType: card.answerType,
        card,
        setAlertMessageForCorrectAnswer,
        setAttemptsCountForToday,
        setCardIndex,
        setIncorrectCards,
        setIsButtonSpinnerMarkAsCorrectDisplayed,
        setIsFormDisplayed,
        setResult,
        setSaveStatus,
        setUserAnswer,
        setWasShowAnswerButtonClicked,
        userAnswer,
        user
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [card, userAnswer, user]
  );

  useEffect(() => {
    const handleGoToNextCardKeyboardShortcut = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleGoToNextCard(event);
      } else if (
        result === false &&
        (event.key === 'c' || event.key === 'C') &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        event.preventDefault();
        handleMarkAsCorrect(card, userAnswer, user);
      }
    };

    document.addEventListener('keydown', handleGoToNextCardKeyboardShortcut);

    return () => {
      document.removeEventListener('keydown', handleGoToNextCardKeyboardShortcut);
    };
  }, [card, handleGoToNextCard, handleMarkAsCorrect, result, user, userAnswer]);

  useEffect(() => {
    setAlertMessageForCorrectAnswer(null);
  });

  useEffect(() => {
    if (topDivRef.current) {
      const obj: any = { behavior: 'instant' };
      topDivRef.current.scrollIntoView(obj);
    }
  }, []);

  return (
    <div ref={topDivRef}>
      {result ? (
        <>
          <p>
            <FaThumbsUp className="text-green-500 text-6xl align-middle mr-3" /> Correct!
          </p>
        </>
      ) : (
        <>
          <p>
            <MdClose className="text-red-500 text-7xl align-middle" /> Incorrect.
          </p>
          <p>
            <strong>Your Answer:</strong> {userAnswerFormatted}
          </p>
        </>
      )}

      <CardToReviewCorrectAnswer correctAnswer={card.answer} keywords={card.keywords} />

      <CardToReviewAnswerNotes answerNotes={card.answerNotes} />

      <TooltipForButton text="Enter">
        <button
          style={{ width: '10.4rem' }}
          type="button"
          className="btn btn-primary mr-2"
          disabled={isButtonSpinnerIsDisplayed ? true : false}
          onClick={(e) => {
            handleGoToNextCard(e);
          }}
        >
          {isButtonSpinnerIsDisplayed ? <ClipLoader size={15} /> : <>Go to Next Card</>}
        </button>
      </TooltipForButton>

      {result === false && (
        <TooltipForButton text="Mark as Correct (C)">
          <button
            style={{ width: '10.4rem' }}
            type="submit"
            className="btn bg-green-600 hover:bg-green-700 text-white w-36 mr-2"
            disabled={isButtonSpinnerMarkAsCorrectIsDisplayed ? true : false}
            onClick={async () => {
              handleMarkAsCorrect(card, userAnswer, user);
            }}
          >
            {isButtonSpinnerMarkAsCorrectIsDisplayed ? (
              <ClipLoader size={15} />
            ) : (
              <>
                Mark as <strong>C</strong>orrect
              </>
            )}
          </button>
        </TooltipForButton>
      )}
    </div>
  );
};

export default Result;
