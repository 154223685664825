import { format } from 'date-fns';
import getTable from '../helpers/getTable';

const calculatePointsOfToday = async (
  isMounted: boolean,
  attemptsCountForToday: number,
  setPointsOfToday: React.Dispatch<React.SetStateAction<undefined | number>>,
  setNumOfCreatedCards: React.Dispatch<React.SetStateAction<undefined | number>>,
  setPointsLeftToMeetGoal: React.Dispatch<React.SetStateAction<undefined | number>>,
  user: any
) => {
  const cardsTable = getTable(user, 'cards');

  let pointsOfToday = 0;

  const dateStringOfToday: string = format(new Date(), 'yyyy-MM-dd');

  const cardsCreatedTodayFromAirtable = await cardsTable
    .select({
      filterByFormula: `"${dateStringOfToday}" = DATETIME_FORMAT({dateCreated}, "YYYY-MM-DD")`,
      view: 'All (Filtered) - Sorted by dateCreated',
      fields: ['_id']
    })
    .firstPage();

  pointsOfToday += cardsCreatedTodayFromAirtable.length * 10;

  pointsOfToday += attemptsCountForToday;

  const numOfCreatedCards = (pointsOfToday - attemptsCountForToday) / 10;
  const pointsLeftToMeetGoal = 20 - pointsOfToday;

  if (isMounted) {
    setPointsOfToday(pointsOfToday);
    setNumOfCreatedCards(numOfCreatedCards);
    setPointsLeftToMeetGoal(pointsLeftToMeetGoal);
  }
};

export default calculatePointsOfToday;
