export function calculateNumOfCardsToGo(
  cardsLength: number,
  cardIndex: number,
  incorrectCardsLength: number,
  isFormDisplayed: boolean,
  result?: boolean
) {
  return (
    cardsLength -
    cardIndex +
    incorrectCardsLength -
    (!isFormDisplayed && result === false ? 1 : 0)
  );
}
