import getTable from '../helpers/getTable';
import calculateNextAttemptDate from './calculateNextAttemptDate';
import formatDateInAirtableFormat from '../utils/dates/formatDateInAirtableFormat';
import CardType from '../typescript/CardType';
import SaveStatusType from '../typescript/SaveStatusType';

const createAttemptAndUpdateCard = async (
  card: CardType,
  result: boolean,
  userAnswer: string,
  setSaveStatus: React.Dispatch<React.SetStateAction<SaveStatusType>>,
  user: any
) => {
  try {
    const nextAttemptDateFromAlgorithm = await calculateNextAttemptDate(
      card._id,
      result,
      user
    );

    const attemptFields = JSON.parse(
      JSON.stringify({
        card_id: [card.id],
        date: formatDateInAirtableFormat(new Date()),
        result,
        answer: userAnswer
      })
    );

    setSaveStatus('saving');

    const attemptsTable = getTable(user, 'attempts');
    const cardsTable = getTable(user, 'cards');

    await Promise.all([
      attemptsTable.create([{ fields: attemptFields }]),
      cardsTable.update([{ id: card.id, fields: { nextAttemptDateFromAlgorithm } }])
    ]);

    setSaveStatus('idle');
  } catch (err) {
    setSaveStatus('error');

    console.error(err);
  }
};

export default createAttemptAndUpdateCard;
