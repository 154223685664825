import getTable from '../helpers/getTable';
import AirtableImageType from '../typescript/AirtableImageType';
import ImageType from '../typescript/ImageType';

const getImages = async (cardId: string, user: any) => {
  try {
    const imagesTable = getTable(user, 'images');

    const imagesFromAirtable: any = await imagesTable
      .select({
        filterByFormula: `FIND(${cardId}, ARRAYJOIN({card_id},','))`,
        sort: [{ field: '_id', direction: 'asc' }]
      })
      .firstPage();

    const images: AirtableImageType[] = imagesFromAirtable;

    const formattedImages: ImageType[] = images.map((image) => ({
      id: image.id,
      ...image.fields
    }));

    return formattedImages;
  } catch (err) {
    console.error(err);
  }
};

export default getImages;
