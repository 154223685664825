import * as React from 'react';

type Props = {
  children: any;
};

const MainSectionBody = ({ children }: Props) => {
  return <div className="grow shrink basis-auto px-7 py-5">{children}</div>;
};

export default MainSectionBody;
