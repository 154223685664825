import React from 'react';
import AnswerStyleType from '../typescript/AnswerStyleType';

const formatUserAnswer = (
  cardAnswerStyle: AnswerStyleType,
  userAnswer: string,
  result?: boolean
) => {
  let userAnswerCopy: string | JSX.Element = userAnswer;

  if (cardAnswerStyle === 'code' || cardAnswerStyle === 'htmlCode') {
    if (cardAnswerStyle === 'htmlCode') {
      if (!result) {
        userAnswerCopy = userAnswerCopy.replace(/</g, '&lt;');
      }
    }

    if (!result) {
      userAnswerCopy = (
        <span
          style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{
            __html: userAnswerCopy.replace(/(.*)/g, '<code>$1</code>')
          }}
        />
      );
    }
  }

  return userAnswerCopy;
};

export default formatUserAnswer;
