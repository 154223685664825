import calculatenumberOfDaysToAddBeforeFuzzing from './calculateNumberOfDaysToAddBeforeFuzzing';

const calculatenumberOfDaysToAdd = (
  mostRecentCountOfConsecutiveCorrectAttempts: number,
  result: boolean | undefined
) => {
  const numberOfDaysToAddBeforeFuzzing = calculatenumberOfDaysToAddBeforeFuzzing(
    mostRecentCountOfConsecutiveCorrectAttempts,
    result
  );

  let numberOfDaysToAdd: number;

  const rand = Math.random();

  if (numberOfDaysToAddBeforeFuzzing <= 2) {
    numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing;
    return numberOfDaysToAdd;
  }

  switch (numberOfDaysToAddBeforeFuzzing) {
    case 4:
      if (rand < 0.8) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing;
      } else {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 1;
      }
      break;

    case 8:
      if (rand < 0.8) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing;
      } else {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 1;
      }
      break;

    case 16:
      if (rand < 0.45) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing;
      } else if (rand > 0.45 && rand < 0.85) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 1;
      } else if (rand > 0.85 && rand < 0.95) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 2;
      } else {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 3;
      }
      break;

    default:
      if (rand < 0.2) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing;
      } else if (rand > 0.2 && rand < 0.4) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 1;
      } else if (rand > 0.4 && rand < 0.6) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 2;
      } else if (rand > 0.6 && rand < 0.8) {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 3;
      } else {
        numberOfDaysToAdd = numberOfDaysToAddBeforeFuzzing - 4;
      }
      break;
  }

  return numberOfDaysToAdd;
};

export default calculatenumberOfDaysToAdd;
