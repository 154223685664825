import getTable from '../helpers/getTable';
import AirtableAttemptType from '../typescript/AirtableAttemptType';
import AttemptType from '../typescript/AttemptType';

const getAttemptsByCardId = async (cardId: string, user: any) => {
  try {
    const attemptsTable = getTable(user, 'attempts');

    let attemptsFromAirtable: any = await attemptsTable
      .select({
        filterByFormula: `${cardId} = ARRAYJOIN({card_id},',')`,
        sort: [{ field: 'date', direction: 'desc' }]
      })
      .firstPage();

    const attempts: AirtableAttemptType[] = attemptsFromAirtable;

    const formattedAttempts: AttemptType[] = attempts.map(
      (attempt: AirtableAttemptType) => ({
        id: attempt.id,
        ...attempt.fields
      })
    );

    return formattedAttempts;
  } catch (err) {
    console.error(err);
  }
};

export default getAttemptsByCardId;
