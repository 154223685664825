import * as React from 'react';

type Props = {
  children: any;
};

const MainSection = ({ children }: Props) => {
  return (
    <div
      style={{
        backgroundClip: 'initial',
        wordWrap: 'break-word'
      }}
      className="
        relative
        flex
        flex-col
        min-w-0
        max-w-3xl
        w-full
        bg-white
        border-t
        border-b
        border-solid
        border-gray-300
        md:border
        md:rounded
        mb-10
      "
    >
      {children}
    </div>
  );
};

export default MainSection;
