import React from 'react';
import { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import hasAuthorizedEmailForProduction from '../../helpers/auth/hasAuthorizedEmailForProduction';
import hasAuthorizedEmailForDevelopment from '../../helpers/auth/hasAuthorizedEmailForDevelopment';
import calculatePointsOfToday from '../../data/calculatePointsOfToday';
import ExtraCredit from './ExtraCredit';
import CardType from '../../typescript/CardType';
import MainSectionBody from '../MainSectionBody/MainSectionBody';
import SaveStatusType from '../../typescript/SaveStatusType';

type Props = {
  attemptsCountForToday: number;
  saveStatus: SaveStatusType;
  setCards: React.Dispatch<React.SetStateAction<CardType[] | undefined>>;
  user: any;
};

function DailyStatusBody({ attemptsCountForToday, saveStatus, setCards, user }: Props) {
  const [numOfCreatedCards, setNumOfCreatedCards] = useState<undefined | number>(
    undefined
  );
  const [pointsLeftToMeetGoal, setPointsLeftToMeetGoal] = useState<undefined | number>(
    undefined
  );
  const [pointsOfToday, setPointsOfToday] = useState<undefined | number>(undefined);

  useEffect(() => {
    let isMounted: boolean = true;

    if (hasAuthorizedEmailForProduction(user)) {
      calculatePointsOfToday(
        isMounted,
        attemptsCountForToday,
        setPointsOfToday,
        setNumOfCreatedCards,
        setPointsLeftToMeetGoal,
        user
      );
    } else if (hasAuthorizedEmailForDevelopment(user)) {
      setPointsOfToday(0);
    }

    return () => {
      isMounted = false;
    };
  }, [attemptsCountForToday, user]);

  return (
    <>
      <MainSectionBody>
        {saveStatus !== 'idle' || pointsOfToday === undefined ? (
          <ClipLoader color="#666" size={15} />
        ) : (
          <>
            {hasAuthorizedEmailForProduction(user) && (
              <>
                <p>
                  You reviewed {attemptsCountForToday} card
                  {attemptsCountForToday !== 1 ? 's' : ''} and created {numOfCreatedCards}{' '}
                  card
                  {numOfCreatedCards !== 1 ? 's' : ''} today for a total of{' '}
                  {pointsOfToday} point
                  {pointsOfToday !== 1 ? 's' : ''}.
                </p>

                {pointsOfToday >= 20 ? (
                  <>
                    <p>You reached your points goal for today.</p>
                    <p className="text-5xl">🎉</p>
                  </>
                ) : (
                  <p>
                    You need {pointsLeftToMeetGoal} more point
                    {pointsLeftToMeetGoal !== 1 ? 's' : ''} to reach your points goal for
                    today. Let's go!
                  </p>
                )}
              </>
            )}

            {hasAuthorizedEmailForDevelopment(user) && (
              <>
                <p>
                  You reviewed {attemptsCountForToday} card
                  {attemptsCountForToday !== 1 ? 's' : ''} today.
                </p>
              </>
            )}

            {attemptsCountForToday > 20 && (
              <p>
                Also, you reviewed {attemptsCountForToday - 20} cards for extra credit. 🚀
              </p>
            )}
          </>
        )}

        <ExtraCredit
          attemptsCountForToday={attemptsCountForToday}
          saveStatus={saveStatus}
          setCards={setCards}
          user={user}
        />
      </MainSectionBody>
    </>
  );
}

export default DailyStatusBody;
