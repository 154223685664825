import * as React from 'react';
import { useState, useEffect } from 'react';
import getImages from '../../data/getImages';
import calculateLineBreakCountOfAnswer from '../../helpers/calculateLineBreakCountOfAnswer';
import MainSection from '../MainSection/MainSection';
import CardToReviewHeader from './CardToReviewHeader';
import { calculateNumOfCardsToGo } from './CardToReview.helpers';
import MainSectionBody from '../MainSectionBody/MainSectionBody';
import CardBody from '../CardBody/CardBody';
import UserAnswerForm from '../UserAnswerForm/UserAnswerForm';
import CardToReviewResult from './CardToReviewResult';
import CardType from '../../typescript/CardType';
import ImageType from '../../typescript/ImageType';
import Props from './CardToReview.props';
import CardToReviewComparison from './CardToReviewComparison';

function CardToReview({
  alertMessageForCorrectAnswer,
  cards,
  setAlertMessageForCorrectAnswer,
  setAttemptsCountForToday,
  setCards,
  setSaveStatus,
  user
}: Props) {
  const [card, setCard] = useState<CardType>(cards[0]);
  const [cardIndex, setCardIndex] = useState<number>(0);
  const [images, setImages] = useState<undefined | ImageType[]>(undefined);
  const [incorrectCards, setIncorrectCards] = useState<CardType[]>([]);
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [result, setResult] = useState<undefined | boolean>(undefined);
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [wasShowAnswerButtonClicked, setWasShowAnswerButtonClicked] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchData(card: CardType) {
      const imagesFromData: ImageType[] | undefined = await getImages(card._id, user);
      if (imagesFromData) setImages(imagesFromData);
    }

    fetchData(card);
  }, [card, user]);

  useEffect(() => {
    if (cards[cardIndex]) {
      setCard(cards[cardIndex]);
    } else {
      if (incorrectCards.length > 0) {
        setCards(incorrectCards);
        setIncorrectCards([]);
        setCardIndex(0);
      } else {
        setCards([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, cardIndex, incorrectCards]);

  useEffect(() => {
    if (isFormDisplayed) {
      window.scrollTo(0, 0);
    }
  }, [card, isFormDisplayed]);

  const lineBreakCountOfAnswer: number = calculateLineBreakCountOfAnswer(card.answer);

  return (
    <MainSection>
      <CardToReviewHeader
        alertMessageForCorrectAnswer={alertMessageForCorrectAnswer}
        cardId={card.id}
        numOfCardsToGo={calculateNumOfCardsToGo(
          cards.length,
          cardIndex,
          incorrectCards.length,
          isFormDisplayed,
          result
        )}
        setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
      />

      <MainSectionBody>
        <CardBody card={card} images={images} />

        <hr />

        {isFormDisplayed ? (
          <UserAnswerForm
            card={card}
            lineBreakCountOfAnswer={lineBreakCountOfAnswer}
            setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
            setAttemptsCountForToday={setAttemptsCountForToday}
            setCardIndex={setCardIndex}
            setIncorrectCards={setIncorrectCards}
            setIsFormDisplayed={setIsFormDisplayed}
            setResult={setResult}
            setSaveStatus={setSaveStatus}
            setUserAnswer={setUserAnswer}
            setWasShowAnswerButtonClicked={setWasShowAnswerButtonClicked}
            userAnswer={userAnswer}
            user={user}
          />
        ) : (
          <>
            {(card.answerType === 'exact' || card.answerType === 'keywords') && (
              <CardToReviewResult
                card={card}
                result={result}
                setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
                setAttemptsCountForToday={setAttemptsCountForToday}
                setCardIndex={setCardIndex}
                setIncorrectCards={setIncorrectCards}
                setIsFormDisplayed={setIsFormDisplayed}
                setResult={setResult}
                setSaveStatus={setSaveStatus}
                setUserAnswer={setUserAnswer}
                setWasShowAnswerButtonClicked={setWasShowAnswerButtonClicked}
                user={user}
                userAnswer={userAnswer}
              />
            )}

            {card.answerType === 'comparison' && (
              <CardToReviewComparison
                card={card}
                result={result}
                wasShowAnswerButtonClicked={wasShowAnswerButtonClicked}
                setAlertMessageForCorrectAnswer={setAlertMessageForCorrectAnswer}
                setAttemptsCountForToday={setAttemptsCountForToday}
                setCardIndex={setCardIndex}
                setIncorrectCards={setIncorrectCards}
                setIsFormDisplayed={setIsFormDisplayed}
                setResult={setResult}
                setSaveStatus={setSaveStatus}
                setUserAnswer={setUserAnswer}
                setWasShowAnswerButtonClicked={setWasShowAnswerButtonClicked}
                user={user}
                userAnswer={userAnswer}
              />
            )}
          </>
        )}
      </MainSectionBody>
    </MainSection>
  );
}

export default CardToReview;
