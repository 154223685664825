// @ts-nocheck
import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ClerkProvider, SignIn, SignUp, SignedIn, SignedOut } from '@clerk/clerk-react';
import DatabaseResetProvider from './components/DatabaseResetProvider/DatabaseResetProvider';
import HomePage from './pages/HomePage/HomePage';
import PublicHeader from './components/PublicHeader';

const frontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;

const App = () => {
  const navigate = useNavigate();

  return (
    <ClerkProvider frontendApi={frontendApi} navigate={(to) => navigate(to)}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <DatabaseResetProvider>
                  <div className="flex justify-center mt-8 max-w-6xl w-full md:px-4 mx-auto">
                    <HomePage />
                  </div>
                </DatabaseResetProvider>
              </SignedIn>
              <SignedOut>
                <PublicHeader />
                <div className="flex justify-center mt-4 max-w-6xl w-full md:px-4 mx-auto">
                  <div className="mt-10 py-4 px-6 border border-1">
                    <ul>
                      <li>
                        <a href="/sign-in">Sign In</a>
                      </li>
                      <li>
                        <a href="/sign-up">Sign Up</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </SignedOut>
            </>
          }
        />
        <Route path="/sign-in" element={<SignIn signUpUrl="/sign-up" />} />
        <Route path="/sign-up" element={<SignUp signInUrl="/sign-in" />} />
        <Route
          path="/cards/:_id"
          element={
            <>
              <SignedIn>
                <div>Card Component</div>
              </SignedIn>
              <SignedOut>
                <div>
                  <ul>
                    <li>
                      <a href="/sign-in">Sign In</a>
                    </li>
                    <li>
                      <a href="/sign-up">Sign Up</a>
                    </li>
                  </ul>
                </div>
              </SignedOut>
            </>
          }
        />
      </Routes>
    </ClerkProvider>
  );
};

export default App;
