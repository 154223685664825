import hasAuthorizedEmailForProduction from './auth/hasAuthorizedEmailForProduction';
import hasAuthorizedEmailForDevelopment from './auth/hasAuthorizedEmailForDevelopment';
import tables from '../airtable';

const getTable = (
  user: any,
  name: 'cards' | 'attempts' | 'images' | 'dailyEmailNotifications' | 'actionsCountByDate'
) => {
  if (hasAuthorizedEmailForProduction(user)) {
    return tables[name].production;
  }

  if (hasAuthorizedEmailForDevelopment(user)) {
    return tables[name].development;
  }

  throw new Error('User is not authorized to access Airtable');
};

export default getTable;
