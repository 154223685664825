import authorizedEmails from '../../data/authorizedEmails';

export default function hasAuthorizedEmail(user: any): boolean {
  return (
    !authorizedEmails.productionUsers.includes(
      String(user?.primaryEmailAddress?.emailAddress)
    ) &&
    !authorizedEmails.developmentUsers.includes(
      String(user?.primaryEmailAddress?.emailAddress)
    )
  );
}
