import * as React from 'react';
import hasAuthorizedEmailForDevelopment from '../../helpers/auth/hasAuthorizedEmailForDevelopment';

type Props = {
  children: any;
  user: any;
};

const MainSectionHeaderActions = ({ children, user }: Props) => {
  return (
    <div className="flex flex-row items-center">
      {hasAuthorizedEmailForDevelopment(user) && (
        <span className="px-2 border border-dashed border-gray-300 rounded-md mr-2 text-black bg-green-100">
          Test Mode
        </span>
      )}{' '}
      {children}
    </div>
  );
};

export default MainSectionHeaderActions;
